import axios from "axios";
const currentUrl = window.location.href;
let url = "";
let urlCheckout = "";
let urlBackbaze = "";
let urlSite = "";

// Verifica Qual base está ativo
if (currentUrl.includes("serhumanodesenvolvimento.com.br")) {
  url = "https://api.serhumanodesenvolvimento.com.br";
  urlCheckout = "https://payfast.serhumanodesenvolvimento.com.br";
  urlBackbaze = "https://api-erp.devaribox.co";
  urlSite = "https://mutase.com.br";
} else if (currentUrl.includes("devaribox.co")) {
  url = "https://serhumano-api.devaribox.co";
  urlCheckout = "https://mutase-checkout.devaribox.co";
  urlBackbaze = "https://api-erp.devaribox.co";
  urlSite = "https://mutase.devaribox.co";
} else if (currentUrl.includes(":300")) {
  url = "https://serhumano-api.devaribox.co";
  // url = "https://api.serhumanodesenvolvimento.com.br";
  // url = "http://localhost:8000";
  urlCheckout = "https://mutase-checkout.devaribox.co";
  urlBackbaze = "https://api-erp.devaribox.co";
  urlSite = "https://mutase.devaribox.co";
  // urlBackbaze = "http://192.168.1.10:3000";
  // urlCheckout = "http://192.168.1.6:8000";
}

export const baseUrlCheckout = urlCheckout;
export const baseUrlBackBlaze = urlBackbaze;
export const baseSiteUrl = urlSite;

export const baseURL = url;
export default axios.create({
  baseURL: `${baseURL}/api/v1/`,
});

let nfURL = "";
// Verifica Qual base está ativo
if (currentUrl.includes("serhumanodesenvolvimento.com.br")) {
  nfURL = "https://nfe-api.devaribox.co"; // troca pra url de produção quando for criada
} else if (currentUrl.includes("devaribox.co")) {
  nfURL = "https://nfe-api.devaribox.co";
} else if (currentUrl.includes(":3002")) {
  // nfURL = "https://serhumano-api.devaribox.co";
  // nfURL = "https://api.serhumanodesenvolvimento.com.br";
  nfURL = "http://localhost:3000";
}

export const nfBaseURL = nfURL;

let payURL = "";
let devPayToken = "";

// Verifica Qual base está ativo
if (currentUrl.includes("serhumanodesenvolvimento.com.br")) {
  payURL = "https://pay.devari.com.br";
  devPayToken = "Token d41b413d803b6814b322c49ff69e7c2c0c905bf1";
} else if (currentUrl.includes("devaribox.co")) {
  payURL = "https://pay-dev.devaribox.co/api/v1";
  devPayToken = "Token bbb2f3bd230f32711dd64558d594eb3fb5dfdd5f";
} else if (currentUrl.includes(":3002")) {
  // url = "https://mutase-api.devaribox.co";
  // payURL = "https://pay-dev.devaribox.co/api/v1";
  payURL = "http://localhost:8000";
  devPayToken = "Token bbb2f3bd230f32711dd64558d594eb3fb5dfdd5f";
}

export const payBaseURL = payURL;
export const devariPayToken = devPayToken;

//Devari Taks
let talksUrl = "";
let talksToken = "";
let talksApp = "";

// Verifica Qual base está ativo
if (currentUrl.includes("serhumanodesenvolvimento.com.br")) {
  talksUrl = "https://talks.devaribox.co";
  // talksToken = "Token d41b413d803b6814b322c49ff69e7c2c0c905bf1";
  let talksApp = "631b7adee409a7f5e646e9c9";
} else if (currentUrl.includes("devaribox.co")) {
  talksUrl = "https://talks.devaribox.co";
  // talksToken = "Token bbb2f3bd230f32711dd64558d594eb3fb5dfdd5f";
  let talksApp = "631b7adee409a7f5e646e9c9";
} else if (currentUrl.includes(":3000")) {
  talksUrl = "https://talks.devaribox.co";
  // talksUrl = "http://localhost:3001";
  // talksToken = "Token bbb2f3bd230f32711dd64558d594eb3fb5dfdd5f";
  talksApp = "631b7adee409a7f5e646e9c9";
}

export { talksUrl, talksToken, talksApp };
